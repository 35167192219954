import React, { useContext } from "react";
import bannerStarimage from "../../assets/images/banner-starimage.png";
import bannerWaveimage from "../../assets/images/banner-waveimage.png";
import { Link } from "react-router-dom";
import ActiveNavContext from "../../context/ActiveNavContext";

export default function SubBanner({ title, page }) {
  const { setPageState, activeNav } = useContext(ActiveNavContext);

  return (
    <div className="banner_outer position-relative">
      <section className="sub_banner_con">
        <div className="container">
          <div className="sub_banner_content position-relative">
            <h1>{title}</h1>
            <div className="content">
              <p className="col-lg-6 col-md-6 mb-0 p-0">
                Embark on a journey of knowledge and skill enhancement with
                Circulate Skill Development.
              </p>
              <div className="box">
                <Link
                  to="/"
                  className="text-decoration-none"
                  onClick={() => {
                    activeNav("home");
                    setPageState({
                      pageActive: false,
                      coursePageActive: false,
                    });
                  }}
                >
                  <span className="mb-0">Home</span>
                </Link>
                <span className="tilde">~</span>
                <span className="mb-0 box_span">{page}</span>
              </div>
            </div>
            <figure className="banner-starimage mb-0">
              <img src={bannerStarimage} alt="" className="img-fluid"></img>
            </figure>
            <figure className="banner-waveimage mb-0">
              <img src={bannerWaveimage} alt="" className="img-fluid"></img>
            </figure>
          </div>
        </div>
      </section>
    </div>
  );
}
