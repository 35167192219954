import React from "react";
import PageLayout from "../components/home/PageLayout";
import Home from "../pages/Home";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Courses from "../pages/Courses";
import SingleCourse from "../pages/SingleCourse";
import Instructor from "../pages/Instructor";
import InstructorSpotlight from "../pages/InstructorSpotlight";
import Event from "../pages/Event";
import EventsDetails from "../pages/EventsDetails";
import Testimonial from "../pages/Testimonial";
import Pricing from "../pages/Pricing";
import Faq from "../pages/Faq";
import FinancialAid from "../pages/FinancialAid";
import "../assets/css/responsive.css";
import ErrorPage from "../components/errorPage/ErrorPage";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Login from "../admin/Login";
import NavBar from "../components/adminNav/NavBar";
import StudentsDetails from "../admin/StudentsDetails";
import Training from "../pages/Training";
import AddStudent from "../components/studentDetailsForm/AddStudent";
import AdminRoute from "../admin/adminRoute/AdminRoute";
import AdminHome from "../admin/AdminHome";

const Routes = () => {
  return [
    {
      path: "/",
      element: <PageLayout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/about",
          element: <About />,
        },
        {
          path: "/contact",
          element: <Contact />,
        },
        {
          path: "/course",
          element: <Courses />,
        },
        {
          path: "/singleCourse/:id?",
          element: <SingleCourse />,
        },
        {
          path: "/instructors",
          element: <Instructor />,
        },
        {
          path: "/instructorSpotlight",
          element: <InstructorSpotlight />,
        },
        {
          path: "/events",
          element: <Event />,
        },
        {
          path: "/eventDetail/:id?",
          element: <EventsDetails />,
        },
        {
          path: "/testimonial",
          element: <Testimonial />,
        },
        {
          path: "/pricing",
          element: <Pricing />,
        },
        {
          path: "/faq",
          element: <Faq />,
        },
        {
          path: "/financialAid",
          element: <FinancialAid />,
        },
        {
          path: "/privacyPolicy",
          element: <PrivacyPolicy />,
        },
        {
          path: "/training",
          element: <Training />,
        },
      ],
      errorElement: <ErrorPage />,
    },
    {
      path: "/admin",
      element: <Login />,
    },
    {
      path: "/admin",
      element: <NavBar />,
      children: [
        {
          path: "/admin/home",
          element: <AdminRoute element={<AdminHome />} />,
        },
        {
          path: "/admin/studentsDetails",
          element: <AdminRoute element={<StudentsDetails />} />,
        },

        {
          path: "/admin/addStudentDetails",
          element: <AdminRoute element={<AddStudent />} />,
        },
        {
          path: "/admin/updateStudentDetails/:id",
          element: <AdminRoute element={<AddStudent />} />,
        },
      ],
    },
  ];
};

export default Routes;
